<template>
  <div>
    <div
      class="fixed inset-0 translate-z-0 bg-white/10 z-50"
      @click="maskClick"
      v-if="show"
    />
    <van-popup
      v-if="show"
      v-model="popup.show"
      position="top"
      class="rounded-b-lg"
    >
      <div class="text-left">
        <div class="font-bold">为给您提供更好的服务</div>
        <div class="font-text">
          根据微信平台相关政策调整，为优化用户使用体验，请授权用户头像和昵称相关信息
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { weixinAuthorizeUserInfo } from '@/features/checkLogin';

export default {
  components: {},
  data() {
    return {
      popup: {
        show: false,
      },
      me: this.$store.state.is_new,
    };
  },
  computed: {
    show() {
      return +this.me;
    },
  },
  methods: {
    maskClick() {
      this.popup.show = true;
      weixinAuthorizeUserInfo();
    },
  },
};
</script>

<style lang="scss" scoped>
.text-left {
  padding: 10px;
  .font-bold{
    font-size: 15px;
  }

  .font-text {
    padding-top: 6px;
    line-height: 22px;
    font-size: 15px;
  }
}
</style>