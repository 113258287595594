import api from '../index'
import urls from './urls'

const header = {}

export default {
  infos(data, params) {//获取装机人员绑定的信息
    return api.post(urls.infos, params, header, data)
  },
  bind(data, params) {//获取装机人员绑定
    return api.post(urls.bind, params, header, data)
  },
  bindInfos(data, params) {//新获取装机人员绑定的信息
    return api.post(urls.bindInfos, params, header, data)
  },
  bindWorker(data, params) {//新获取装机人员绑定
    return api.post(urls.bindWorker, params, header, data)
  },
  fields(data, params) {//获取城市考场官方列表
    return api.post(urls.fields, params, header, data)
  },
  submitTag(data,params) {//提交标签修改
    return api.post(urls.submitTag, params, header, data)
  },
  copyOff(data,params) {//设置官方线路
    return api.post(urls.copyOff, params, header, data)
  },
  createCode(data,params) {//创建临时激活码
    return api.post(urls.createCode, params, header, data)
  },
  getRateList(data,params) {//获取分佣比例表
    return api.post(urls.getRateList, params, header, data)
  },
  settingRate(data,params) {//设置分佣比例
    return api.post(urls.settingRate, params, header, data)
  },
  getQr(data,params) {//获取邀请教练二维码
    return api.post(urls.getQr, params, header, data)
  },
  // getDevices(params) {//获取设备列表/获取设备详情
  //   return api.get(urls.getDevices, params, header)
  // },
  getRevenue(params) {//获取收益列表
    return api.get(urls.getRevenue, params, header)
  },
  getDevLine(params) {//获取已上传线路列表
    return api.get(urls.getDevLine, params, header)
  },
  getSnLine(params) {//获取已上传线路列表2级
    return api.get(urls.getSnLine, params, header)
  },
  getCodeList(params) {//获取临时激活码列表
    return api.get(urls.getCodeList, params, header)
  },
  getLineInfo(params) {//获取线路详情
    return api.get(urls.getLineInfo, params, header)
  },
  getTestRes(params) {//信号检测结果接口
    return api.get(urls.getTestRes, params, header)
  },
  getIncomeData(params) {//获取收入头部数据
    return api.get(urls.getIncomeData, params, header)
  },
  getBaseInfo(params) {//获取装机人员基本信息
    return api.get(urls.getBaseInfo, params, header)
  },
  getCityList(params) {//获取省市数据
    return api.get(urls.getCityList, params, header)
  },
  getRate(data,params) {//获取分佣比例表
    return api.post(urls.getRate, params, header, data)
  },
  setRate(data,params) {//设置分佣比例
    return api.post(urls.setRate, params, header, data)
  },
  practExamLst(params) {// 设备审核列表
    return api.get(urls.practExamLst, params, header)
  },
  practStatus(data,params) {// 设备审核
    return api.post(urls.practStatus, params, header, data)
  },
  fqDevice(params) {// 分期设备列表
    return api.get(urls.fqDevice, params, header)
  },
}
