import common from './common'
import admin from './admin'
import qr from './qr'
import dst from './dst'
import agent from './agent'
import worker from './worker'
import student from './student'

export default {
  common,
  admin,
  dst,
  qr,
  agent,
  student,
  worker
}