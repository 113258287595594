import api from '../index'
import urls from './urls'

const header = {}

export default {
  parameters(data, params) {//获取扫码支付参数检验
    return api.post(urls.parameters, params, header, data)
  },

  pay(data, params) {//获取扫码支付信息
    return api.post(urls.pay, params, header, data)
  },

  cancel(data, params) {//取消扫码支付
    return api.post(urls.cancel, params, header, data)
  },
  payActCode(data, params) {//支付激活码
    return api.post(urls.payActCode, params, header, data)
  },
  getProInfo(params) {//获取产品信息
    return api.get(urls.getProInfo, params, header)
  },
}
