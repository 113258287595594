import baseUrl from '../baseUrl'
export default {
	wx: baseUrl + '/v1/wx',
	sdk: baseUrl + '/v1/wx/sdk',
	uploginLog: baseUrl + '/v1/login/login-log',
	appid: baseUrl + '/v1/wx/appid',
	refreshToken: baseUrl + '/v1/wx/refresh-token',
	device: baseUrl + '/v1/common/device',
	unbind: baseUrl + '/v1/common/unbind',
	upMobile: baseUrl + '/v1/common/up-mobile',

	sendCode: baseUrl + '/v1/site/sms-code',
	register: baseUrl + '/v1/register/register',
	regSta: baseUrl + '/v1/register/reg-sta',
	getRoleCode: baseUrl + '/v1/login/before-login',
	checkDst: baseUrl + '/v1/login/check-dst',
	checkLogin: baseUrl + '/v1/login/check-login',
}
