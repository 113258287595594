import api from '../index'
import urls from './urls'

const header = {}

export default {
 
    getdeviceAdmin(params) {//超管权限
        return api.get(urls.getdeviceAdmin, params, header)
    },
    adminDevice(params) {//超管权限列表
        return api.get(urls.adminDevice, params, header)
    },
    deviceInfo(params) {//设备信息
        return api.get(urls.deviceInfo, params, header)
    },
    deviceAct(data,params) {//解绑、延期等
        return api.post(urls.deviceAct, params, header,data)
    },

    fieldbinddev(data,params) { //授权设备
        return api.post(urls.fieldbinddev,params,header,data)
    },
    collFieldList(params) { //授权列表
        return api.get(urls.collFieldList,params,header)
    },
    fieldList(params) { //添加移除列表
        return api.get(urls.fieldList,params,header)
    },
    fieldact(data,params) { //添加移除
        return api.post(urls.fieldact,params,header,data)
    },
    unbindDev(data,params) {
        return api.post(urls.unbindDev,params,header,data)
    },
    devList(params) {
        return api.get(urls.devList,params,header)
    },
    fieldAdmin(params) {
        return api.get(urls.fieldAdmin,params,header)
    }
}
