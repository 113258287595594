/**
 * getUrlKey 截取地址栏参数
 * @param value
 * @returns {String}
 */
function getUrlKey(name) {
	return (
		decodeURIComponent(
			(new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(
				location.href
			) || [, ''])[1].replace(/\+/g, '%20')
		) || null
	)
}

/**
 * delUrlKey 删除地址栏参数
 * @param value
 * @returns {String}
 */
function delUrlKey(name, yurl) {
	if (!yurl) {
		yurl = decodeURIComponent(window.location.href)
	}

	let burl = yurl.split("#")[0];
	let hash = yurl.split("#")[1];
	let urlArr = burl.split("?");
	let baseUrl = urlArr[0] ? urlArr[0] + "?" : '';
	let query = urlArr[1] ? urlArr[1] : '';

	if (query.indexOf(name) > -1) {
		let obj = {};
		let arr = query.split("&");
		for (let i = 0; i < arr.length; i++) {
			arr[i] = arr[i].split("=");
			obj[arr[i][0]] = arr[i][1];
		};

		delete obj[name];

		let url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");
		return url + '#' + hash
	}

	return yurl
}

/**
 * editUrlKey 修改地址栏参数
 * @param value
 * @returns {String}
 */
function editUrlKey(name, val, yurl) {
	if (!yurl) {
		yurl = decodeURIComponent(window.location.href)
	}

	let burl = yurl.split("#")[0];
	let hash = yurl.split("#")[1];
	let urlArr = burl.split("?");
	let baseUrl = urlArr[0] ? urlArr[0] + "?" : '';
	let query = urlArr[1] ? urlArr[1] : '';

	let obj = {}
	let arr = query.split("&");
	for (let i = 0; i < arr.length; i++) {
		arr[i] = arr[i].split("=");
		obj[arr[i][0]] = arr[i][1];
	}

	obj[name] = val;

	var url = baseUrl + JSON.stringify(obj).replace(/[\"\{\}]/g, "").replace(/\:/g, "=").replace(/\,/g, "&");

	return url + '#' + hash
}

function sslUrl(url, flag, h, w) {
	return url ? url.replace("http://", 'https://') : ''
}

/**
 * 输入金额控制
 */
function numberCheck(val) {
	var str = val;
	var len1 = str.substr(0, 1);
	var len2 = str.substr(1, 1);
	// 如果第一位是 0, 第二位不是点, 就用数字把点替换掉
	if (str.length > 1 && len1 == 0 && len2 != ".") {
		str = str.substr(1, 1);
	}
	// 第一位不能是.
	if (len1 == ".") {
		str = "";
	}
	// 限制只能输入一个小数点
	if (str.indexOf(".") != -1) {
		var str_ = str.substr(str.indexOf(".") + 1);
		if (str_.indexOf(".") != -1) {
			str = str.substr(0, str.indexOf(".") + str_.indexOf(".") + 1);
		}
	}
	// 正则替换, 保留数字和小数点
	str = (str.match(/^\d*(\.?\d{0,1})/g)[0]) || null
	return str;
}

export default {
	getUrlKey,
	delUrlKey,
	editUrlKey,
	numberCheck,
};