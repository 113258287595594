import api from '../index'
import urls from './urls'

const header = {}

export default {
	wx(params) {//微信授权登录
	  return api.get(urls.wx, params, header)
	},
	appid(params) {//获取微信公众号appid
	  return api.get(urls.appid, params, header)
	},
	
	refreshToken(data,params) {//刷新token
	  return api.post(urls.refreshToken, params, header,data)
	}, 
	uploginLog(data,params) {//上传登录日志
	  return api.post(urls.uploginLog, params, header,data)
	}, 
    sendCode(data,params) {//获取验证码
      return api.post(urls.sendCode, params, header,data)
    },
    getRoleCode(data,params) {//获取用户身份状态码
      return api.post(urls.getRoleCode, params, header,data)
    },
    register(data,params) {//注册装机人员
      return api.post(urls.register, params, header,data)
    },
    regSta(data,params) {//注册装机人员
      return api.post(urls.regSta, params, header,data)
    },
    upMobile(data,params) {//绑定/换绑手机
      return api.post(urls.upMobile, params, header,data)
    },
	device(data,params) {//获取设备信息
	  return api.post(urls.device, params, header,data)
	},
	unbind(data,params) {//解除装机人员/教练的设备绑定
	  return api.post(urls.unbind, params, header,data)
	},
	checkLogin(data,params) {//检查登录状态
	  return api.post(urls.checkLogin, params, header,data)
	},
	checkDst(data,params) {//检查是否已经登录设备
	  return api.post(urls.checkDst, params, header,data)
	},

}
