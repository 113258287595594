import {
    Loading, Empty, Skeleton, Checkbox, CheckboxGroup,
    Tabbar, TabbarItem, Calendar, DatetimePicker,
    Form, Field, PasswordInput, TreeSelect, Picker, Switch, NumberKeyboard,
    Button, Image, Icon, CountDown, Badge, Tag,
    List, Cell, CellGroup, Cascader, Radio, RadioGroup,
    NavBar, Search, Overlay, Stepper, ActionSheet,
    Popup, Dialog, Notify, Toast, Sticky,
    Collapse, CollapseItem,
    DropdownMenu, DropdownItem,
    Tabs, Tab, Area, Uploader,
} from 'vant';

const uses = [
    Loading, Empty, Skeleton, Checkbox, CheckboxGroup,
    Tabbar, TabbarItem, Calendar, DatetimePicker,
    Form, Field, PasswordInput, TreeSelect, Picker, Switch, NumberKeyboard,
    Button, Image, Icon, CountDown, Badge, Tag,
    List, Cell, CellGroup, Cascader, Radio, RadioGroup,
    NavBar, Search, Overlay, Stepper, ActionSheet,
    Popup, Dialog, Notify, Toast, Sticky, 
    Collapse, CollapseItem,
    DropdownMenu, DropdownItem,
    Tabs, Tab, Area, Uploader, 
]

Toast.allowMultiple(true);

export default {
    install(app){
        for(const item of uses){
            app.use(item)
        }
    }
}