import baseUrl from '../baseUrl'
export default {
  getdeviceAdmin: baseUrl + '/v1/device/is-device-admin',
  adminDevice: baseUrl + '/v1/device/admin-device',
  deviceInfo: baseUrl + '/v1/device/admin-device-info',
  deviceAct: baseUrl + '/v1/device/device-act',
  
  fieldbinddev: baseUrl + '/v1/worker/field-bind-dev',
  collFieldList: baseUrl + '/v1/worker/collect-field-list',
  fieldList:  baseUrl + '/v1/worker/field-list',
  fieldact: baseUrl + '/v1/worker/field-act',
  unbindDev: baseUrl + '/v1/worker/del-field-role',
  devList: baseUrl + '/v1/worker/field-dev-list',
  fieldAdmin : baseUrl + '/v1/worker/is-field-admin'
}
