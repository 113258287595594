import api from '../index'
import urls from './urls'

const header = {}

export default {
  getDevices(params) {//获取设备列表/获取设备详情
    return api.get(urls.getDevices, params, header)
  },
  getRevenue(params) {//获取收益列表
    return api.get(urls.getRevenue, params, header)
  },
  getBindInfo(params) {//获取解绑数据
    return api.get(urls.getBindInfo, params, header)
  },
  getMoneyDetail(params) {//获取提现明细
    return api.get(urls.getMoneyDetail, params, header)
  },
  getMoneyNum(params) {//获取可提现金额
    return api.get(urls.getMoneyNum, params, header)
  },
  getDevDetailInfo(params) {//获取设备详情
    return api.get(urls.getDevDetailInfo, params, header)
  },
  getExistCity(params) {//获取已有城市列表
    return api.get(urls.getExistCity, params, header)
  },
  getExistExaRoom(params) {//获取已添加考场列表
    return api.get(urls.getExistExaRoom, params, header)
  },
  getField(params) {//获取已有考场列表
    return api.get(urls.getField, params, header)
  },
  getIncomeData(params) {//获取收入头部数据
    return api.get(urls.getIncomeData, params, header)
  },

  editMountStatus(data, params) {//设备列表 修改高精度状态
    return api.post(urls.editMountStatus, params, header, data)
  },
  delayExp(data, params) {//设备列表 激活有效期修改
    return api.post(urls.delayExp, params, header, data)
  },
  editPrice(data, params) {//修改设备价格
    return api.post(urls.editPrice, params, header, data)
  },
  submitMoney(data, params) {//提交提现申请
    return api.post(urls.submitMoney, params, header, data)
  },
  smsCode(data, params) {//发送验证码
    return api.post(urls.smsCode, params, header, data)
  },
  signIn(data, params) {//注册教练
    return api.post(urls.signIn, params, header, data)
  },
  unbind(data, params) {//设备解绑
    return api.post(urls.unbind, params, header, data)
  },
  syncLine(data, params) {//同步线路
    return api.post(urls.syncLine, params, header, data)
  },
  actField(data, params) {//添加/移除考场
    return api.post(urls.actField, params, header, data)
  },

  deviceManager(params) {//驾校管理人设备列表
    return api.get(urls.deviceManager, params, header)
  },
  deviceManEdit(data, params) {//添加，移除，取别名操作
    return api.post(urls.deviceManEdit, params, header, data)
  },
  practiceList(params) {// 成绩列表
    return api.get(urls.practiceList, params, header)
  },
  practiceDetail(params) {// 成绩详情
    return api.get(urls.practiceDetail, params, header)
  },
  bindPractice(data, params) {// 用户绑定成绩单
    return api.post(urls.bindPractice, params, header, data)
  },
  practiceData(data, params) {// 获取合并数据
    return api.post(urls.practiceData, params, header, data)
  },
  practApply(data, params) {// 设备申请授权
    return api.post(urls.practApply, params, header, data)
  },
  practiceInfo(params) {// 驾校设备详情
    return api.get(urls.practiceInfo, params, header)
  },
  createPractOrder(data, params) {// 创建收费时长
    return api.post(urls.createPractOrder, params, header, data)
  },
  practiceTip(data, params) {// 时间结束提醒
    return api.post(urls.practiceTip, params, header, data)
  },
  uploadFile(data, params) {// 上传文件
    return api.post(urls.uploadFile, params, header, data)
  },
}
