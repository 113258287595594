export const setToken = (access_token) => {
  sessionStorage.setItem('auth', JSON.stringify({
    access_token,
    expires_in: ~~(Date.now() / 1000) + 60 * 60 * 24 
  }))
}

export const getToken = () => {
  const token = sessionStorage.getItem('auth')
  if (token) {
    return token
  } else {
    return false
  }
}