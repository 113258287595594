import wx from 'weixin-js-sdk'
import axios from 'axios'
import common from '@/api/common/urls'
import commonIndex from '@/api/common'
import { jsonp } from 'vue-jsonp'
import router from '@/router'

const wxApi = {
  /**
  * [wxRegister 微信Api初始化]
  * @param  {Function} callback [ready回调函数]
  */
  wxRegister(callback, jsapi, url) {
    // 这边的接口请换成你们自己的
    let reqUrl = url ? url.split("#")[0] : window.location.href.split("#")[0]
    let apiList = jsapi ? jsapi : "updateAppMessageShareData,updateTimelineShareData,hideMenuItems,showMenuItems,getLocation"

    axios.post(common.sdk, { 'url': reqUrl, 'jsapi': apiList }).then((res) => {
      let data = res.data.data // PS: 这里根据你接口的返回值来使用
      wx.config({
        debug: false, // 开启调试模式
        appId: data.appId, // 必填，公众号的唯一标识
        timestamp: data.timestamp, // 必填，生成签名的时间戳
        nonceStr: data.nonceStr, // 必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见附录1
        // jsApiList: data.jsApiList // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        jsApiList: ['getLocation', 'chooseWXPay', 'onMenuShareAppMessage', 'onMenuShareTimeline', 'showMenuItems', 'closeWindow', 'hideMenuItems'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
      })
      wx.ready((res) => {
        // 如果需要定制ready回调方法
        callback && callback()
      })
      wx.error(function (res) {
        console.log(res, 'wx.error');
      });
    }).catch((error) => {
      console.log('wxsdk', error)
    })
  },
  /**
  * [ShareTimeline 微信分享到朋友圈]
  * @param {[type]} option [分享信息]
  * @param {[type]} success [成功回调]
  * @param {[type]} error   [失败回调]
  */
  ShareTimeline(option) {
    wx.updateTimelineShareData({
      title: option.title, // 分享标题
      link: option.link, // 分享链接
      imgUrl: option.imgUrl, // 分享图标
      success() {
        // 用户成功分享后执行的回调函数
        option.success()
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        option.error()
      }
    })
  },
  /**
  * [ShareAppMessage 微信分享给朋友]
  * @param {[type]} option [分享信息]
  * @param {[type]} success [成功回调]
  * @param {[type]} error   [失败回调]
  */
  ShareAppMessage(option) {
    wx.updateAppMessageShareData({
      title: option.title, // 分享标题
      desc: option.desc, // 分享描述
      link: option.link, // 分享链接
      imgUrl: option.imgUrl, // 分享图标
      success() {
        // 用户成功分享后执行的回调函数
        option.success()
      },
      cancel() {
        // 用户取消分享后执行的回调函数
        option.cancel()
      }
    })
  },
  /**
  * [ShareAppMessage 微信支付]
  * @param {[type]} option [支付信息]
  * @param {[type]} success [成功回调]
  * @param {[type]} error   [失败回调]
  */
  ChooseWXPay(option) {
    wx.chooseWXPay({
      'timestamp': option.timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
      'nonceStr': option.nonceStr, // 支付签名随机串，不长于 32 位
      'package': option.packageStr, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
      'signType': option.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
      'paySign': option.paySign, // 支付签名
      success() {
        option.success()
      },
      cancel() {
        option.cancel()
      },
      fail() {
        option.fail()
      }
    })
  },
  /* 调用微信扫一扫 */
  ScanQRCode() {
    wx.scanQRCode({
      needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
      scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
      success: function (res) {
        var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        window.location.href = result;//因为我这边是扫描后有个链接，然后跳转到该页面
      }
    });
  },
  /* 微信获取定位 */
  getLocation() {
    const uploginLog = (obj = {
      longitude: '',
      latitude: '',
      city: '',
      province: "",
      platform: 1,
      address: "",
    }) => {
      if (!window.location.origin) {
        window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
      }

      commonIndex.uploginLog(obj).then((res) => {
        // location.href = window.location.origin + '/#' + sessionStorage.getItem("yurl").split("#")[1]
        // router.push(sessionStorage.getItem("yurl").split("#")[1] || "/")
      }).catch((err) => {
        // location.href = window.location.origin + '/#' + sessionStorage.getItem("yurl").split("#")[1]
        // router.push(sessionStorage.getItem("yurl").split("#")[1] || "/")
      })
    }
    wx.getLocation({
      type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
      success: function (res) {
        console.log(res, 'getLocation成功');
        var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
        var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
        //GPS坐标转换
        let url = 'https://apis.map.qq.com/ws/geocoder/v1/?location=' + latitude + ',' + longitude + '&type=1' + '&key=I7RBZ-BJNCP-ZRMDE-LYL27-Q6RV5-H4BCX&get_poi=1'
        jsonp(url, {
          output: 'jsonp'
        }).then((json) => {
          json = json.result
          let params = {
            longitude: longitude,
            latitude: latitude,
            city: json.ad_info.city,
            province: json.ad_info.province,
            platform: 1,
            address: json.address
          }
          sessionStorage.setItem('city', json.ad_info.province + '/' + json.ad_info.city)
          uploginLog(params)
        }).catch((err) => {
          uploginLog()
        })
      },
      cancel: function(err) {
        uploginLog({platform: 1,address: "用户拒绝授权获取地址位置"})
      },
      fail: function (err) {
        uploginLog()
      }
    });
  },
  /* 隐藏分享 */
  hideMenuItems() {
    wx.hideMenuItems({
      menuList: ["menuItem:copyUrl", "menuItem:exposeArticle", "menuItem:editTag", 'menuItem:share:qq', "menuItem:share:QZone", 'menuItem:share:appMessage', "menuItem:share:timeline"]
    });
  },
}
export default wxApi