import vant from './vant'
import viewDesign from './viewDesign'
import VueWechatTitle from 'vue-wechat-title'

export default {
    install(app){
        app.use(vant)
        app.use(viewDesign)
        app.use(VueWechatTitle)
    }
}