import Vue from 'vue'
import VueRouter from 'vue-router'

/******* 公共 *******/
const Login = () => import('@/views/Login') //微信授权登录
const Register = () => import('@/views/Register')  //注册页面
const UserRegister = () => import('@/views/userRegister')  //用户注册页面
const SelectRole = () => import('@/views/common/selectRole')  //选择登陆身份
const Unbind = () => import('@/views/common/unbind')  //将设备与装机人员解绑
const Info = () => import('@/views/common/info')  //个人中心
const err404 = () => import('@/views/common/404')  //404页面
const Redirect = () => import('@/views/common/redirect')  //重定向页面
const phone = () => import('@/views/common/phone')  //绑定手机号
const QrCodeAcc = () => import('@/components/qrCodeAcc')  //公众号二维码页面

/******* 学员相关 *******/
//const Home = () => import('@/views/Home')   //首页
const Pay = () => import('@/views/qr/pay')  //扫码支付确认页
const PayActCode = () => import('@/views/qr/payActCode')  //扫码购买设备激活码页
const Order = () => import('@/views/student/order.vue')  //订单页

/******* 装机人员相关 *******/
const Worker = () => import('@/views/dst/dst')  //装机管理首页
// const BindWorker = () => import('@/views/worker/bind')  //将设备与装机人员绑定....已弃用
const BindWorkerNew = () => import('@/views/worker/bindNew')  //新将设备与装机人员绑定
const FunList = () => import('@/views/worker/funList')  //装机人员功能列表
const UploadedList = () => import('@/views/worker/uploadedList')  //已上传线路列表
const UploadedList2 = () => import('@/views/worker/uploadedList2')  //已上传线路列表2
const LineInfo = () => import('@/views/worker/lineInfo')  //线路详情
const LabelSet = () => import('@/views/worker/labelSet')  //标签设置
const codeList = () => import('@/views/worker/codeList')  //临时验证码列表
const TestResult = () => import('@/views/worker/testResult')  //检测结果
const inviteQr = () => import('@/views/worker/inviteQr')  //邀请教练绑定
const rateList = () => import('@/views/worker/rateList')  //分佣比例
const validityList = () => import('@/views/worker/validityList')  //有效期列表
const deviceApplyLst = () => import('@/views/worker/deviceApplyLst')  // 设备审核列表
const stagesDevice = () => import('@/views/worker/stagesDevice')  // 分期试用设备列表

/******* 教练相关 *******/
const Dst = () => import('@/views/dst/dst')  //教练管理首页
const MyDevice = () => import('@/views/dst/myDevice')  //我的设备
const MyIncome = () => import('@/views/dst/myIncome')  //我的收入
const DeviceDetail = () => import('@/views/dst/deviceDetail')  //设备详情
const GetMoney = () => import('@/views/dst/getMoney')  //提现
const MoneyDetail = () => import('@/views/dst/moneyDetail')  //提现明细
const AccIncome = () => import('@/views/dst/accIncome')  //累计收入
const UnbindByPhone = () => import('@/views/dst/unbindByPhone')  //通过手机号解绑
const LineManage = () => import('@/views/dst/lineManage')  //线路管理
const ChoiceCity = () => import('@/views/dst/choiceCity')  //选择城市
const SelectExaRoom = () => import('@/views/dst/selectExaRoom')  //选择考场
const SignIn = () => import('@/views/dst/signIn')  //获取登录验证码
const HPDetail = () => import('@/views/dst/highPrecisionDetail')  //高精度详情
const DeviceManageLst = () => import('@/views/dst/deviceManageLst')  // 驾校设备管理列表
const DeviceManageDetail = () => import('@/views/dst/deviceManageDetail')  // 驾校设备详情
const PractiseDriving = () => import('@/views/dst/practiseDriving')  // 练车记录
const PracticeRecordDetail = () => import('@/views/dst/practiceRecordDetail')  // 练车详情
const CreateGradeLst = () => import('@/views/dst/createGradeLst')  // 设备列表

/******* 超级管理 *******/
const Aindex = () => import('@/views/admin/index')  //超管首页
const Adph = () => import('@/views/admin/dph')

// 解决跳转重复异常提醒问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

import { Toast } from 'vant';
Vue.use(VueRouter)

const routes = [
	//交由重定向页面进行处理
	{
		path: '*',
		redirect: '/redirect'
	},
	{
		path: '/redirect',
		name: 'Redirect',
		component: Redirect
	},
	{
		path: '/qrCodeAcc',
		name: 'QrCodeAcc',
		component: QrCodeAcc
	},
	{
		path: '/register/:c',
		name: 'Register',
		component: Register
	},
	{
		path: '/userRegister',
		name: 'UserRegister',
		component: UserRegister
	},
	{
		path: '/login',
		name: 'Login',
		component: Login
	},
	{
		path: '/pay',
		name: 'Pay',
		component: Pay
	},
	{
		path: '/phone',
		name: 'phone',
		component: phone
	},
	{
		path: '/payActCode',
		name: 'PayActCode',
		component: PayActCode
	},
	{
		path: '/student/practiseDriving',
		name: 'studentPraDriving',
		meta: {
			auth: 'is_student'
		},
		component: PractiseDriving,
	},
	{
		path: '/student/practiceRecordDetail',
		name: 'studentPraRecordDetail',
		meta: {
			auth: 'is_student'
		},
		component: PracticeRecordDetail,
	},
	{
		path: '/student/createGradeLst',
		name: 'studentCreateGradeLst',
		meta: {
			auth: 'is_student'
		},
		component: CreateGradeLst
	},
	{
		path: '/student/deviceManageLst',
		name: 'studentDevManageLst',
		meta: {
			auth: 'is_student'
		},
		component: DeviceManageLst,
	},
	{
		path: '/student/deviceManageDetail',
		name: 'studentDevManageDetail',
		meta: {
			auth: 'is_student'
		},
		component: DeviceManageDetail,
	},
	{
		path: '/signIn',
		name: 'SignIn',
		component: SignIn
	},
	{
		path: '/selectRole',
		name: 'SelectRole',
		component: SelectRole
	},
	{
		path: '/unbind/:g/:d/:c',
		name: 'Unbind',
		component: Unbind
	},
	{
		path: '/404',
		name: '404',
		component: err404
	},

	// {
	// 	path: '/bind-worker/:d/:c',
	// 	name: 'BindWorker',
	// 	component: BindWorker
	// },
	{
		path: '/bind-workerNew/:d/:c',
		name: 'BindWorkerNew',
		component: BindWorkerNew
	},
	//教练相关
	{
		path: '/dst',
		redirect: '/dst/myIncome',
		name: 'Dst',
		component: Dst,
		meta: {
			auth: 'is_dst'
		},
		children: [
			{
				path: 'myIncome',//以“/”开头的嵌套路径会被当作根路径，所以子路由上不用加“/”;在生成路由时，主路由上的path会被自动添加到子路由之前，所以子路由上的path不用在重新声明主路由上的path了。
				name: 'DstMyIncome',
				meta: {
					auth: 'is_dst'
				},
				component: MyIncome
			},
			{
				path: 'myDevice',
				name: 'DstMyDevice',
				meta: {
					auth: 'is_dst'
				},
				component: MyDevice
			},
			{
				path: 'info',
				name: 'DstInfo',
				meta: {
					auth: 'is_dst'
				},
				component: Info
			},
			{
				path: 'order',
				name: 'DstOrder',
				meta: {
					auth: 'is_dst'
				},
				component: Order
			},
		]
	},
	{
		path: '/dst/deviceDetail',
		name: 'DstDeviceDetail',
		meta: {
			auth: 'is_dst'
		},
		component: DeviceDetail
	},
	{
		path: '/dst/funList',
		name: 'DstFunList',
		meta: {
			auth: 'is_dst'
		},
		component: FunList
	},
	{
		path: '/dst/getMoney',
		name: 'DstGetMoney',
		meta: {
			auth: 'is_dst'
		},
		component: GetMoney
	},
	{
		path: '/dst/moneyDetail',
		name: 'DstMoneyDetail',
		meta: {
			auth: 'is_dst'
		},
		component: MoneyDetail
	},
	{
		path: '/dst/unbindByPhone',
		name: 'DstUnbindByPhone',
		meta: {
			auth: 'is_dst'
		},
		component: UnbindByPhone
	},
	{
		path: '/dst/lineManage',
		name: 'DstLineManage',
		meta: {
			auth: 'is_dst'
		},
		component: LineManage
	},
	{
		path: '/dst/choiceCity',
		name: 'DstChoiceCity',
		meta: {
			auth: 'is_dst'
		},
		component: ChoiceCity
	},
	{
		path: '/dst/selectExaRoom/:id/:title/:device_id',
		name: 'DstSelectExaRoom',
		meta: {
			auth: 'is_dst'
		},
		component: SelectExaRoom
	},
	{
		path: '/dst/accIncome',
		name: 'DstAccIncome',
		meta: {
			auth: 'is_dst'
		},
		component: AccIncome
	},
	{
		path: '/dst/highPrecisionDetail',
		name: 'DstHighPDetail',
		meta: {
			auth: 'is_dst'
		},
		component: HPDetail
	},
	{
		path: '/dst/deviceManageLst',
		name: 'dstDevManageLst',
		meta: {
			auth: 'is_dst'
		},
		component: DeviceManageLst,
	},
	{
		path: '/dst/deviceManageDetail',
		name: 'dstDevManageDetail',
		meta: {
			auth: 'is_dst'
		},
		component: DeviceManageDetail,
	},
	{
		path: '/dst/practiseDriving',
		name: 'dstPraDriving',
		meta: {
			auth: 'is_dst'
		},
		component: PractiseDriving,
	},
	{
		path: '/dst/practiceRecordDetail',
		name: 'dstPraRecordDetail',
		meta: {
			auth: 'is_dst'
		},
		component: PracticeRecordDetail,
	},
	{
		path: '/dst/createGradeLst',
		name: 'dstCreateGradeLst',
		meta: {
			auth: 'is_dst'
		},
		component: CreateGradeLst
	},

	//装机人员相关 //页面大多与教练一致,但参数及权限不一样
	{
		path: '/worker',
		redirect: '/worker/myIncome',
		name: 'Worker',
		component: Worker,
		meta: {
			auth: 'is_worker'
		},
		children: [
			{
				path: 'myIncome',
				name: 'WorkerMyIncome',
				meta: {
					auth: 'is_worker'
				},
				component: MyIncome
			},
			{
				path: 'myDevice',
				name: 'WorkerMyDevice',
				meta: {
					auth: 'is_worker'
				},
				component: MyDevice
			},
			{
				path: 'info',
				name: 'WorkerInfo',
				meta: {
					auth: 'is_worker'
				},
				component: Info
			},
			{
				path: 'order',
				name: 'WorkerOrder',
				meta: {
					auth: 'is_worker'
				},
				component: Order
			},
		]
	},
	{
		path: '/worker/deviceDetail',
		name: 'WorkerDeviceDetail',
		meta: {
			auth: 'is_worker'
		},
		component: DeviceDetail
	},
	{
		path: '/worker/funList',
		name: 'WorkerFunList',
		meta: {
			auth: 'is_worker'
		},
		component: FunList
	},
	{
		path: '/worker/validityList',
		name: 'ValidityList',
		meta: {
			auth: 'is_worker'
		},
		component: validityList
	},
	{
		path: '/worker/deviceApplyLst',
		name: 'DeviceApplyLst',
		meta: {
			auth: 'is_worker'
		},
		component: deviceApplyLst
	},
	{
		path: '/worker/stagesDevice',
		name: 'StagesDevice',
		meta: {
			auth: 'is_worker'
		},
		component: stagesDevice
	},
	{
		path: '/worker/deviceManageLst',
		name: 'workerDevManageLst',
		meta: {
			auth: 'is_worker'
		},
		component: DeviceManageLst,
	},
	{
		path: '/worker/deviceManageDetail',
		name: 'workerDevManageDetail',
		meta: {
			auth: 'is_worker'
		},
		component: DeviceManageDetail,
	},
	{
		path: '/worker/practiseDriving',
		name: 'workerPraDriving',
		meta: {
			auth: 'is_worker'
		},
		component: PractiseDriving
	},
	{
		path: '/worker/practiceRecordDetail',
		name: 'workerPraRecordDetail',
		meta: {
			auth: 'is_worker'
		},
		component: PracticeRecordDetail
	},
	{
		path: '/worker/createGradeLst',
		name: 'workerCreateGradeLst',
		meta: {
			auth: 'is_worker'
		},
		component: CreateGradeLst
	},
	{
		path: '/worker/uploadedList',
		name: 'WorkerUploadedList',
		meta: {
			auth: 'is_worker'
		},
		component: UploadedList
	},
	{
		path: '/worker/uploadedList2',
		name: 'WorkerUploadedList2',
		meta: {
			auth: 'is_worker'
		},
		component: UploadedList2
	},
	{
		path: '/worker/lineInfo',
		name: 'WorkerLineInfo',
		meta: {
			auth: 'is_worker'
		},
		component: LineInfo
	},
	{
		path: '/worker/rateList',
		name: 'WorkerRateList',
		meta: {
			auth: 'is_worker'
		},
		component: rateList
	},
	{
		path: '/worker/inviteQr',
		name: 'WorkerInviteQr',
		meta: {
			auth: 'is_worker'
		},
		component: inviteQr
	},
	{
		path: '/worker/labelSet',
		name: 'WorkerLabelSet',
		meta: {
			auth: 'is_worker'
		},
		component: LabelSet
	},
	{
		path: '/worker/codeList',
		name: 'WorkerCodeList',
		meta: {
			auth: 'is_worker'
		},
		component: codeList
	},
	{
		path: '/worker/testResult',
		name: 'WorkerTestResult',
		meta: {
			auth: 'is_worker'
		},
		component: TestResult
	},
	{
		path: '/worker/getMoney',
		name: 'WorkerGetMoney',
		meta: {
			auth: 'is_worker'
		},
		component: GetMoney
	},
	{
		path: '/worker/moneyDetail',
		name: 'WorkerMoneyDetail',
		meta: {
			auth: 'is_worker'
		},
		component: MoneyDetail
	},
	{
		path: '/worker/accIncome',
		name: 'WorkerAccIncome',
		meta: {
			auth: 'is_worker'
		},
		component: AccIncome
	},

	//代理人员相关 //页面大多与教练一致,但参数及权限不一样
	{
		path: '/agent',
		redirect: '/agent/myIncome',
		name: 'Agent',
		component: Worker,
		meta: {
			auth: 'is_agent'
		},
		children: [
			{
				path: 'myIncome',
				name: 'AgentMyIncome',
				meta: {
					auth: 'is_agent'
				},
				component: MyIncome
			},
			{
				path: 'order',
				name: 'AgentOrder',
				meta: {
					auth: 'is_agent'
				},
				component: Order
			},
			{
				path: 'info',
				name: 'AgentInfo',
				meta: {
					auth: 'is_agent'
				},
				component: Info
			},
		]
	},
	{
		path: '/agent/getMoney',
		name: 'AgentGetMoney',
		meta: {
			auth: 'is_agent'
		},
		component: GetMoney
	},
	{
		path: '/agent/moneyDetail',
		name: 'AgentMoneyDetail',
		meta: {
			auth: 'is_agent'
		},
		component: MoneyDetail
	},
	{
		path: '/agent/accIncome',
		name: 'AgentAccIncome',
		meta: {
			auth: 'is_agent'
		},
		component: AccIncome
	},

	//学员相关
	{
		path: '/student',
		redirect: '/student/order',
		name: 'Student',
		meta: {
			auth: 'is_student'
		},
		component: Dst,
		children: [
			{
				path: 'order',
				name: 'StudentOrder',
				meta: {
					auth: 'is_student'
				},
				component: Order
			},
			{
				path: 'info',
				name: 'StudentInfo',
				meta: {
					auth: 'is_student'
				},
				component: Info
			},
		]
	},
	{
		path: '/admin',
		// redirect: '/admin/index',
		name: 'Admin',
		meta: {
			// auth: 'is_agent'
		},
		component: Aindex
	},
	{
		path: '/admin/dph',
		name: 'Adph',
		meta: {
			// auth: 'is_agent'
		},
		component: Adph
	},
	{
		path: '/admin/grant',
		name: 'Grant',
		meta: {

		},
		component: () => import('@/views/admin/grant')
	}
]

const router = new VueRouter({
	mode: 'hash',
	routes
})

router.beforeEach((to, from, next) => {
	Toast.clear();
	let user = sessionStorage.getItem('userInfo')
	if (sessionStorage.getItem("init_sign") == 1) {
		sessionStorage.setItem('init_sign',2)
		router.push(sessionStorage.getItem("yurl").split("#")[1] || "/")
	}
	//todo 获取登陆状态
	if (process.env.NODE_ENV !== "development") {
		if (!user) {
			if (to.name === 'Login') {
				next()
				return
			}
			// 修改登录逻辑，防止重新调整 9.25
			// sessionStorage.setItem("yurl", location.href)
			next({
				name: 'Login'
			})
			return
		}
	}
	//todo   document.documentElement.scrollTop = 0
	//用户角色判断,无权限跳主页,后期可跳404页面
	try {
		if (to.meta.auth == 'is_student') return next()
		to.meta.auth && (JSON.parse(user)[to.meta.auth] || (next('/redirect'), Toast('您没有对应的权限进入此页面')))
	} catch (error) {

	}
	next()
})

export default router
