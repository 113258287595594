import api from '../index'
import urls from './urls'

const header = {}

export default {
  getOrder(params) {//获取订单列表
    return api.get(urls.getOrder, params, header)

  },
  // editPrice(data, params) {//修改设备价格
  //   return api.post(urls.editPrice, params, header, data)
  // },



}

