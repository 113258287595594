import {
    Table
} from 'view-design';

const components = [
    Table
]

export default {
    install(app){
        for(const item of components){
            app.component(item.name, item)
        }
    }
}