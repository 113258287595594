import baseUrl from '../baseUrl'
export default {
  infos: baseUrl + '/v1/worker/infos',
  bind: baseUrl + '/v1/worker/bind',
  bindInfos: baseUrl + '/v1/worker/bind-infos',
  bindWorker: baseUrl + '/v1/worker/bind-worker',
  getCodeList: baseUrl + '/v1/worker/code-list',
  createCode: baseUrl + '/v1/worker/create-code',
  fields: baseUrl + '/v1/city/city-fields',
  getQr: baseUrl + '/v1/site/get-qr',
  // getDevices: baseUrl + '/v1/dst/device',
  getIncomeData: baseUrl + '/v1/worker/income',
  getRevenue: baseUrl + '/v1/worker/revenue-list',
  getRateList: baseUrl + '/v1/worker/rate-list',
  settingRate: baseUrl + '/v1/worker/setting-rate',
  getDevLine: baseUrl + '/v1/worker/dev-line',
  getSnLine: baseUrl + '/v1/worker/sn-line',
  getLineInfo: baseUrl + '/v1/worker/line-info',
  copyOff: baseUrl + '/v1/worker/copy-off',
  getTestRes: baseUrl + '/v1/dst/signal-det-info',
  getBaseInfo: baseUrl + '/v1/worker/dev-info',
  getCityList: baseUrl + '/v1/city/city',
  submitTag: baseUrl + '/v1/worker/edit-tag',
  getRate: baseUrl + '/v1/worker/get-rate',
  setRate: baseUrl + '/v1/worker/set-rate',
  practExamLst: baseUrl + '/v1/practice/exam-list',
  practStatus: baseUrl + '/v1/practice/exam-status',
  fqDevice: baseUrl + '/v1/device/fq-device',
}
