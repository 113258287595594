import api from '../index'
import urls from './urls'

const header = {}

export default {
 
  getRevenue(params) {//获取收益列表
    return api.get(urls.getRevenue, params, header)
  },
  getIncomeData(params) {//获取收入头部数据
    return api.get(urls.getIncomeData, params, header)
  },
 



}
