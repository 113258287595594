import router from '../router'
import axios from 'axios'
import common from './common/urls'
import {Toast} from "vant";


/* 是否有请求正在刷新token */
window.isRefreshing = false

/* 被挂起的请求数组 */
let refreshSubscribers = []

/* push所有请求到数组中 */
function subscribeTokenRefresh(cb) {
	refreshSubscribers.push(cb)
}

/* 刷新请求（refreshSubscribers数组中的请求得到新的token之后会自执行，用新的token去请求数据） */
function onRrefreshed(token) {
	refreshSubscribers.map(cb => cb(token))
}

// 创建 axios 实例
let service = axios.create({
	// headers: {'Access-Control-Allow-Origin': 'application/json'},
	timeout: 60000,
	// withCredentials: true,
})

/*判断token是否过期*/
function isTokenExpired(auth) {
	/*获取本地时间*/
	let nowTime = Math.floor(new Date().getTime() / 1000)
	let expires_in = auth.expires_in ? auth.expires_in : 0
	return expires_in - nowTime
}

function getAuth() {
	//let auth=localStorage.getItem('auth')
	let auth = sessionStorage.getItem('auth')
	return auth ? JSON.parse(auth) : ''
}

// 设置 post、put 默认 Content-Type
//service.defaults.headers.post['Content-Type'] = 'application/json'
//service.defaults.headers.put['Content-Type'] = 'application/json'

// 添加请求拦截器
service.interceptors.request.use(

	(config) => {
		// Toast.loading({
		// 	message: '加载中...',
		// 	forbidClick: true,
		// });
		//解析登录信息
		const authTmp = sessionStorage.getItem('auth')
		let auth = JSON.parse(authTmp)
		let exp = auth ? isTokenExpired(auth) : 0
		//判断是否已登录
		if (auth && auth.access_token && exp > 0) {
			//在请求头中添加token类型、token
			//config.headers.Authorization = 'Bearer ' + auth.access_token
			config.headers.token = auth.access_token
			//判断token是否将要过期
			// console.log(exp, 'token剩余时间');
			//600
			if (exp < 600 && config.url.indexOf('refresh-token') === -1) {
				//判断是否正在刷新
				if (!window.isRefreshing) {
					console.log('没有正在刷新,触发刷新token');
					//将刷新token的标志置为true
					window.isRefreshing = true
					//发起刷新token的请求
					// console.log('旧的token', config.headers.token);
					axios.post(common.refreshToken, { 'token': auth.access_token }).then(res => {
						// console.log('新的token', res.data.data);
						//将标志置为false
						window.isRefreshing = false
						//成功刷新token
						// config.headers.Authorization = 'Bearer ' + res.data.data.access_token
						let ref_expires_in = Math.floor(new Date().getTime() / 1000) + Number(res.data.data.expiration_time)
						let ref_auth = {
							'expires_in': ref_expires_in,//新的截止时间
							'access_token': res.data.data.token,//新的token
						}
						config.headers.token = res.data.data.token

						//更新auth
						sessionStorage.setItem('auth', JSON.stringify(ref_auth))
						//执行数组里的函数,重新发起被挂起的请求 (用新token)
						onRrefreshed(res.data.data.token)
						//执行onRefreshed函数后清空数组中保存的请求
						refreshSubscribers = []
					}).catch(err => {
						console.log('refresh-token-err', err.response.data.message)
					})
				} else {
					// 正在刷新token，将返回一个未执行resolve的promise
					return new Promise((resolve, reject) => {
						let cb = (token) => {
							if (!token) {
								return reject("token refresh error")
							}
							// config.headers.Authorization = 'Bearer ' + token
							config.headers.token = token
							//将请求挂起
							resolve(config)
						}
						subscribeTokenRefresh(cb)
					})
				}
			}
		}
		return config
	},
	(error) => {
		// 请求错误处理
		return Promise.reject(error)
	}
)

// 添加响应拦截器
service.interceptors.response.use(
	response => {
		// Toast.clear()
		checkStatus(response)
		return Promise.resolve(response).then(checkCode)
	},
	error => {
		checkStatus(error.response)
		return Promise.reject(error.response.data.data)
	}
)



// http状态码错误处理
const checkStatus = (res) => {
	// console.log(res)
	switch (res.data.data.status) {
		case 200:
			//成功无需处理
			break;
		case 401:
			// 401: 未登录 
			// 未登录则跳转登录页面，并携带当前页面的路径
			// 在登录成功后返回当前页面，这一步需要在登录页操作。 
			sessionStorage.setItem("yurl", window.location.href)
			// Toast('401 已在其他设备登陆')
			if (process.env.NODE_ENV == "development") {
				// 想在开发环境中做的操作
				Toast("401 身份过期或已在其他设备登陆,请重新登陆")
			} else {
				router.push({ name: 'Login' })
			}
			// router.go(0)
			break;


		case 403:
			// 403 token过期
			// 登录过期对用户进行提示
			// 清除本地token和清空vuex中token对象
			// 跳转登录页面   
			// 清除本地保存的auth
			//console.log('yyy',window.location.href)
			Toast('登陆信息过期,请重新登陆')
			sessionStorage.removeItem('auth')
			sessionStorage.setItem("yurl", window.location.href)
			router.push({
				name: 'Login'
			})
		// break;


		// 404请求不存在
		case 404:
			Toast('接口请求404')
			router.push({
				name: '404'
			})

			break;
		// 其他错误，直接抛出错误提示
		default:
		// console.log('返回异常,状态码:' + res.data.data.status)

	}

}

// 后台自定义 status错误处理
const checkCode = (res) => {
	if (res) {
		return res.data.data
	} else {
		return false
	}
}

/**
 * 创建统一封装过的 axios 实例
 * @return {AxiosInstance}
 */
export default function () {
	return service
}
