import Vue from 'vue'

//判断是否为测试或开发环境
Vue.prototype.$isDevTest = window.isDevTest = !!location.port
// let baseUrl = window.isDevTest ? 'https://lky.examphone.com' : 'http://api.lky.jiakao520.com'
let baseUrl = ''
if (isDevTest) baseUrl = 'http://api.lky.jiakao520.com'
console.log(baseUrl, 'api地址');

// switch (process.env.NODE_ENV) {
//   case 'development':
//     baseUrl = 'http://api.lky.jiakao520.com' // 测试环境url
//     break
//   case 'pre':
//     baseUrl = 'http://api.lky.jiakao520.com' // 预上线环境url
//     break
//   case 'production':
//     baseUrl = 'http://api.lky.jiakao520.com' // 生产环境url
//     break
// }

export default baseUrl.replace(/\/$/, '')
